import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import STYLES from '../styles/const';
import Image from './image';

const Header = () => (
  <Wrapper>
    <h1>
      <Link to="/">
        <Image
          filename="logo-top-ver2.png"
          alt="株式会社アールエムシー　Resource Management Consultant"
        />
      </Link>
    </h1>
  </Wrapper>
);

const Wrapper = styled.header`
  width: ${STYLES.SIZE.CONTENT.WIDTH}px;
  margin: 0 auto;
  padding: 20px 10px 10px;

  h1 {
    width: 400px;
  }
`;

export default Header;
