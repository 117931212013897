import PropTypes from 'prop-types';
import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import STYLES from '../styles/const';
import reset from '../styles/reset';
import Footer from './footer';
import Header from './header';
import Navigation from './navigation';

const GlobalStyle = createGlobalStyle`
  ${reset};
`;

const Layout = ({ children, path }) => {
  return (
    <Wrapper>
      <Border />
      <Header />
      <Navigation path={path} />
      <Main>{children}</Main>
      <Footer />
      <GlobalStyle />
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 100px;
`;

const Border = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${STYLES.COLOR.PRIMARY};
`;

const Main = styled.main`
  width: ${STYLES.SIZE.CONTENT.WIDTH}px;
  margin: 0 auto;
`;

export default Layout;
