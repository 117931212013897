import PropTypes from 'prop-types';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = ({ filename, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              sizes(maxWidth: 900, fit: CONTAIN) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  `);
  const image = data.images.edges.find(n =>
    n.node.relativePath.includes(filename)
  );
  if (!image) return null;
  const imageSizes = image.node.childImageSharp.sizes;
  const polyfillStyles = {
    objectFit: 'contain',
  };
  return <Img alt={alt} sizes={imageSizes} imgStyle={polyfillStyles} />;
};

Image.propTypes = {
  filename: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Image;
