export const MAIL = 'info@rmcon.co';

export const MENU = {
  IDEA: {
    JA: '企業理念',
    EN: 'idea',
  },
  GREETINGS: {
    JA: '代表取締役の挨拶',
    EN: 'greetings',
  },
  OUTLINE: {
    JA: '会社概要',
    EN: 'outline',
  },
  PRIVACY: {
    JA: '個人情報保護方針',
    EN: 'privacy',
  },
  INQUIRY: {
    JA: 'お問い合わせ',
    EN: 'inquiry',
  },
};
