import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { MAIL, MENU } from '../messages';
import STYLES from '../styles/const';

const Footer = () => (
  <Wrapper>
    <Address>
      <span style={{ fontSize: '12px' }}>株式会社アールエムシー</span>
      <span>{'〒900-0012　沖縄県那覇市泊１丁目4-10-201号'}</span>
      <span>{'tel.098-975-5714　fax.098-975-5520'}</span>
    </Address>
    <div>
      <Links>
        <Link to="/">HOME</Link>
        <span>|</span>
        <Link to={`/${MENU.IDEA.EN}`}>{MENU.IDEA.JA}</Link>
        <span>|</span>
        <Link to={`/${MENU.GREETINGS.EN}`}>{MENU.GREETINGS.JA}</Link>
        <span>|</span>
        <Link to={`/${MENU.OUTLINE.EN}`}>{MENU.OUTLINE.JA}</Link>
        <span>|</span>
        <Link to={`/${MENU.PRIVACY.EN}`}>{MENU.PRIVACY.JA}</Link>
        <span>|</span>
        <a href={`mailto:${MAIL}`}>{MENU.INQUIRY.JA}</a>
      </Links>
      <CopyRight>
        <span>{`© ${new Date().getFullYear()} RMC Inc. All Rights Reserved`}</span>
      </CopyRight>
    </div>
  </Wrapper>
);

const Wrapper = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  width: ${STYLES.SIZE.CONTENT.WIDTH}px;
  margin: auto;
  padding-bottom: 20px;
`;

const Address = styled.address`
  display: flex;
  flex-direction: column;
  color: ${STYLES.COLOR.GRAY};
  font-size: 10px;
  line-height: normal;
`;

const Links = styled.div`
  a,
  span {
    color: ${STYLES.COLOR.GRAY};
    font-size: 10px;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    margin: 0 5px;
  }
`;

const CopyRight = styled.div`
  span {
    color: ${STYLES.COLOR.GRAY};
    font-size: 10px;
  }
`;

export default Footer;
