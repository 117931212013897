import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';

import { MAIL, MENU } from '../messages';
import STYLES from '../styles/const';

const Navigation = ({ path }) => (
  <Wrapper>
    <Menu>
      <MenuItem primary={path === `/${MENU.IDEA.EN}`}>
        <Link to={`/${MENU.IDEA.EN}`}>
          <TextJa>{MENU.IDEA.JA}</TextJa>
          <TextEn>{MENU.IDEA.EN}</TextEn>
        </Link>
      </MenuItem>
      <MenuItem primary={path === `/${MENU.GREETINGS.EN}`}>
        <Link to={`/${MENU.GREETINGS.EN}`}>
          <TextJa>{MENU.GREETINGS.JA}</TextJa>
          <TextEn>{MENU.GREETINGS.EN}</TextEn>
        </Link>
      </MenuItem>
      <MenuItem primary={path === `/${MENU.OUTLINE.EN}`}>
        <Link to={`/${MENU.OUTLINE.EN}`}>
          <TextJa>{MENU.OUTLINE.JA}</TextJa>
          <TextEn>{MENU.OUTLINE.EN}</TextEn>
        </Link>
      </MenuItem>
      <MenuItem>
        <a href={`mailto:${MAIL}`}>
          <TextJa>{MENU.INQUIRY.JA}</TextJa>
          <TextEn>{MENU.INQUIRY.EN}</TextEn>
        </a>
      </MenuItem>
    </Menu>
  </Wrapper>
);

const Wrapper = styled.nav`
  width: ${STYLES.SIZE.CONTENT.WIDTH}px;
  margin: 0 auto;
`;

const Menu = styled.ul`
  display: flex;
`;

const MenuItem = styled.li`
  position: relative;
  height: 60px;
  flex: 1;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: ${props =>
    !props.primary ? STYLES.COLOR.GRAY_LIGHTER_20 : STYLES.COLOR.PRIMARY};
  text-align: center;
  transition: 0.5s;
  &:hover {
    border-bottom-color: ${STYLES.COLOR.PRIMARY};
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    text-decoration: none;
  }
`;

const TextJa = styled.span`
  margin: 3px 0 5px;
  color: ${STYLES.COLOR.PRIMARY};
  font-size: 15px;
  font-family: ${STYLES.FONT.FAMILY.MINCHO};
  font-weight: 800;
`;

const TextEn = styled.span`
  color: ${STYLES.COLOR.GRAY};
  font-size: 13px;
`;

Navigation.propTypes = {
  path: PropTypes.string.isRequired,
};

export default Navigation;
